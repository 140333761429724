import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/docs-icon.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/drei-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/jotai-icon.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/pp-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/r3f-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/react-spring-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/uikit-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/zustand-icon.svg");
